import { render, staticRenderFns } from "./zdMessageReview.vue?vue&type=template&id=c77e31fa&scoped=true"
import script from "./zdMessageReview.vue?vue&type=script&lang=js"
export * from "./zdMessageReview.vue?vue&type=script&lang=js"
import style0 from "./zdMessageReview.vue?vue&type=style&index=0&id=c77e31fa&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c77e31fa",
  null
  
)

export default component.exports